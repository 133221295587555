import axios from 'axios'
import { config } from '../utils/config'
import { apiErrorHandler } from './apiErrorHandler'
import { saveAs } from 'file-saver';

export const BaseUrl = {
    Api: config.apiBaseUrl,
    Identity: config.identityBaseUrl,
    Centerpoint: config.centerpointBaseUrl,
}

export function setAuthHeader(token) {
    axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export function setApiKey(apiKey) {
    axios.defaults.headers.common['InternalApiKey'] = apiKey ? apiKey : ''
}

/**
 * @param {string} path 
 * @param {string} baseUrl 
 * @param {ApiOptions} options  
 */
export const get = async (path, baseUrl = BaseUrl.Api, options = null) => {
    const axiosParams = options?.axiosParams || {}
    try {
        const result = await axios({
            method: "get",
            url: `${baseUrl}/${path}`,
            ...axiosParams
        })
        return result.data
    }
    catch (err) {
        await apiErrorHandler(err, options?.statusCodeHandlers)
    }
}

/**
 * @param {string} path 
 * @param {object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const put = async (path, baseUrl = BaseUrl.Api, data, options = null) => {
    const axiosParams = options?.axiosParams || {}
    try {
        const result = await axios({
            method: "put",
            url: `${baseUrl}/${path}`,
            data,
            ...axiosParams
        })
        return result.data
    }
    catch (err) {
        await apiErrorHandler(err, options?.statusCodeHandlers)
    }
}

/**
 * @param {string} path 
 * @param {Object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const post = async (path, baseUrl = BaseUrl.Api, data, options = null) => {
    const axiosParams = options?.axiosParams || {}
    try {
        const result = await axios({
            method: "post",
            url: `${baseUrl}/${path}`,
            data,
            ...axiosParams
        })
        return result.data
    }
    catch (err) {
        await apiErrorHandler(err, options?.statusCodeHandlers)
    }
}

/** 
 * @param {string} path 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const destroy = async (path, baseUrl = BaseUrl.Api, options = null) => {
    const axiosParams = options?.axiosParams || {}
    try {
        const result = await axios({
            method: "delete",
            url: `${baseUrl}/${path}`,
            ...axiosParams
        })
        return result.data
    }
    catch (err) {
        await apiErrorHandler(err, options?.statusCodeHandlers)
    }
}

/** 
 * @param {string} path 
 * @param {string} filename 
 * @param {string} baseUrl 
 * @param {ApiOptions} options 
 */
export const download = async (path, filename = "smartform.pdf", baseUrl = BaseUrl.Api, options = null) => {
    const axiosParams = options?.axiosParams || {}
    try {
        const result = await axios({
            method: "get",
            responseType: 'blob', // important
            url: `${baseUrl}/${path}`,
            ...axiosParams
        })

        saveAs(result.data, filename);
    }
    catch (err) {
        await apiErrorHandler(err, options?.statusCodeHandlers)
    }
}