import { get, post, download, BaseUrl } from './api'

export const getDemoUser = async () => {
  return await get(`api/WebEditor/demouser`, BaseUrl.Api);
}

export const uploadDxml = async (uploadDxmlModel) => {
  return await post(`api/WebEditor/upload`, BaseUrl.Api, uploadDxmlModel);
}

export const downloadDxml = async (dxmlUID) => {
  return await download(`api/WebEditor/download/${dxmlUID}`, `${dxmlUID}.xml`, BaseUrl.Api);
}

export const getOnlineDocuments = async (metaFile) => {
  return await get(`api/WebEditor/getOnlineDocuments/${metaFile}`, BaseUrl.Api);
}

export const createAndUploadDxml = async (createAndUploadDxmlFileRequest) => {
  return await post(`api/WebEditor/createAndUploadDxml`, BaseUrl.Api, createAndUploadDxmlFileRequest);
}