import { useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import { useState, useEffect } from "react";
import { setApiKey } from "../../api/api";
import { downloadDxml, uploadDxml, getOnlineDocuments, createAndUploadDxml, getDemoUser } from "../../api/webEditor";
import { config } from "../../utils/config"
import toast from 'react-hot-toast';
import "./styles.scss"

export default function Demo() {
    const [demoUser, setDemoUser] = useState();
    const [showInteract, setShowInteract] = useState(false);
    const [dxmlUID, setDxmlUID] = useState("Not ready!");
    const [webEditorStartURL, setWebEditorStartURL] = useState(null);
    const [selectedMetaFile, setSelectedMetaFile] = useState(null);
    const [onlineDocuments, setOnlineDocuments] = useState([]);
    const [dataBase64File, setDataBase64File] = useState(null);
    const [dataFileName, setDataFileName] = useState(null);
    const [selectedPredefinedDataFile, setSelectedPredefinedDataFile] = useState(null);
    const { classNames } = useClassNames();

    const metaFiles = [
        { name: "Salesforce", value: "Salesforce" },
        { name: "MfDemo", value: "MfDemo" },
        { name: "Acme", value: "Acme" },
    ]

    const predefinedDataFiles = [
        { name: "MfDemo.xml", value: "MfDemo.xml" },
        { name: "SF - Account.xml", value: "SF - Account.xml" },
        { name: "SF - Case.xml", value: "SF - Case.xml" },
        { name: "SF - Contact", value: "SF - Contact.xml" },
        { name: "SF - Lead.xml", value: "SF - Lead.xml" },
        { name: "SF - Opportunity.xml", value: "SF - Opportunity.xml" },
    ]

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getDemoUser();
            setDemoUser(res);
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData()
    }, []);

    const handleUpload = async (e) => {
        let file = e.target.files[0];

        readFile(file)
    };

    const readFile = (file) => {
        loadAction.execute(async () => {
            const result = await getFileContentAsBase64String(file);
            file["base64"] = result;

            const uploadDxmlRequestModel = {
                base64File: result.split(',')[1],
            }

            try {
                const res = await uploadDxml(uploadDxmlRequestModel);
                setDxmlUID(res.dxmlUID);
                setWebEditorStartURL(res.startURL);
            } catch (err) {
                toast.error(err.response?.data?.Message, {
                    duration: Infinity,
                });
            }

        }, "Failed to upload dxml");
    }

    const getFileContentAsBase64String = file => {
        return new Promise(resolve => {
            let content = "";
            let reader = new FileReader();

            reader.onload = () => {
                content = reader.result;
                resolve(content);
            };

            reader.readAsDataURL(file);
        });
    };

    const handleDownload = async () => {
        await downloadDxml(dxmlUID);
    }

    const handleGetOnlineDocuments = async (metaFile) => {
        loadAction.execute(async () => {
            setSelectedMetaFile(metaFile);
            if (metaFile) {
                try {
                    const res = await getOnlineDocuments(metaFile);
                    setOnlineDocuments(res);
                } catch (err) {
                    toast.error(err.response?.data?.Message, {
                        duration: Infinity,
                    });
                }
            }
        }, "Failed to get online documents");
    }

    const handleDataUpload = async (e) => {
        let file = e.target.files[0];

        readDataFile(file)
    };

    const readDataFile = (file) => {
        loadAction.execute(async () => {
            const result = await getFileContentAsBase64String(file);
            file["base64"] = result;

            setDataBase64File(result.split(',')[1]);
            setDataFileName(file.name);
            setSelectedPredefinedDataFile('');
        }, "Failed to upload dxml");
    }

    const handlePreUploadeDataFileChanged = (dataFile) => {
        setDataBase64File(null);
        setDataFileName(null);
        setSelectedPredefinedDataFile(dataFile);
    }

    const handleOnlineDocumentSelected = async (onlineDocument) => {
        loadAction.execute(async () => {
            if (!dataBase64File && !selectedPredefinedDataFile) {
                toast.error("Data file required!");
                return;
            }

            const createAndUploadDxmlFileRequest = {
                dataAsBase64: dataBase64File ? dataBase64File : selectedPredefinedDataFile,
                metaFile: selectedMetaFile,
                document: onlineDocument.name,
                senderCallBackEndpoint: '',
                senderCallBackPasswor: '',
                senderCallBackUserName: '',
                userName: 'demo1',
            };
            try {
                const res = await createAndUploadDxml(createAndUploadDxmlFileRequest);
                setDxmlUID(res.dxmlUID);
                setWebEditorStartURL(res.startURL);
            } catch (err) {
                toast.error(err.response?.data?.Message, {
                    duration: Infinity,
                });
            }

        }, "Failed to create and upload dxml");
    }

    return (
        <WebEditorSetAuthToken>
            <main className="main-container bg-brand-background z-0 focus:outline-none h-full">
                <div className="max-w-screen-3xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col pb-10 h-full">
                        <div className="max-w-screen-3xl h-full">
                            <div className="px-10 py-10">
                                <h2 className="py-8 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                    Metaforce WebEditor Demo - {demoUser}
                                </h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2">
                                    <div className="flex flex-col">
                                        <div className="my-4">
                                            <div className="w-40 flex justify-center bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                                                <input type="file" className='sr-only' name="pdfUploadfile" id="pdfUploadfile" accept={".xml"}
                                                    onChange={(e) => {
                                                        handleUpload(e);
                                                        e.target.value = null;
                                                    }}
                                                />
                                                <label htmlFor="pdfUploadfile" className="py-2 px-4 w-full text-center">Upload file</label>
                                            </div>
                                            <p className="mt-1 text-sm text-gray-700">DXML UID: {dxmlUID}</p>
                                        </div>
                                        <div className="my-4">
                                            <a
                                                className={classNames(webEditorStartURL ? "text-gray-700 " : "text-gray-300 cursor-not-allowed ", "w-40 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-gray-50")}
                                                href={webEditorStartURL}
                                                rel="noreferrer"
                                                target="_blank"
                                                disabled={webEditorStartURL ? false : true}
                                            >
                                                Open up WebEditor
                                            </a>
                                        </div>
                                        <div className="my-4">
                                            <button
                                                type="button"
                                                className="w-40 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:text-gray-300 disabled:cursor-not-allowed"
                                                onClick={handleDownload}
                                                disabled={webEditorStartURL ? false : true}
                                            >
                                                Download file
                                            </button>
                                        </div>

                                        <div className="mt-10 mb-4">
                                            <button
                                                type="button"
                                                className="w-40 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:text-gray-300 disabled:cursor-not-allowed"
                                                onClick={() => {
                                                    setShowInteract(!showInteract)
                                                    setSelectedMetaFile('')
                                                    setDataBase64File(null)
                                                    setDataFileName(null)
                                                    setSelectedPredefinedDataFile('')
                                                    setOnlineDocuments([])
                                                }}
                                            >
                                                {showInteract ? <span>Hide Interact</span> : <span>Show Interact</span>}
                                            </button>
                                        </div>
                                    </div>
                                    {showInteract && (
                                        <div>
                                            <div className="my-4">
                                                <label className="text-sm font-medium text-gray-700">1. Choose a MetaFile: </label>
                                                <select
                                                    className="bg-white py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
                                                    onChange={(e) => handleGetOnlineDocuments(e.target.value)}
                                                    value={selectedMetaFile}
                                                >
                                                    <option value=''>Choose MetaFile</option>
                                                    {
                                                        metaFiles.map(metaFile => (
                                                            <option key={metaFile.value} value={metaFile.value}>{metaFile.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="my-4">
                                                <p className="text-sm font-medium text-gray-700">2. Choose Data</p>
                                                <div className="my-4">
                                                    <div className="w-40 flex justify-center bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                                                        <input type="file" className='sr-only' name="dataUploadfile" id="dataUploadfile" accept={".xml"}
                                                            onChange={(e) => {
                                                                handleDataUpload(e);
                                                                e.target.value = null;
                                                            }}
                                                        />
                                                        <label htmlFor="dataUploadfile" className="py-2 px-4 w-full text-center">Upload data</label>
                                                    </div>
                                                    <p className="mt-1 text-sm text-gray-700">Data file: {dataFileName}</p>
                                                </div>
                                                <div className="my-4">
                                                    <label className="text-sm font-medium text-gray-700">Or Choose a pre-uploaded datafile: </label>
                                                    <select
                                                        className="bg-white py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
                                                        onChange={(e) => handlePreUploadeDataFileChanged(e.target.value)}
                                                        value={selectedPredefinedDataFile}
                                                    >
                                                        <option value=''>Choose data</option>
                                                        {
                                                            predefinedDataFiles.map(dataFile => (
                                                                <option key={dataFile.value} value={dataFile.value}>{dataFile.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="my-4">
                                                <p className="text-sm font-medium text-gray-700">3. Choose Document to create and upload dxml file</p>
                                                {onlineDocuments && (
                                                    <ul className="my-4 ">
                                                        {onlineDocuments.map(onlineDocument => (
                                                            <li
                                                                key={onlineDocument.name}
                                                                className="w-80 bg-white py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
                                                                onClick={() => handleOnlineDocumentSelected(onlineDocument)}>
                                                                {onlineDocument.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </WebEditorSetAuthToken>
    )
}

const WebEditorSetAuthToken = ({ children }) => {
    setApiKey(config.internalApiKey);

    return (
        <>
            {children}
        </>
    )
}