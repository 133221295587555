
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Demo from './screens/Demo';

import { Toaster } from './toaster';
import { NotFound } from './screens/Errors/NotFound';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { MissingLicense } from './screens/Errors/MissingLicense';

function App() {
  return (
    <div className="app h-screen">
      <Router>

        <Switch>
          <Route exact={true} path="/unauthorized" component={Unauthorized} />
          <Route exact={true} path="/missing-license" component={MissingLicense} />
          <Route exact={true} path="/not-found" component={NotFound} />

          <Route exact={true} path="/" component={Demo} />
        </Switch>

      </Router>
      <Toaster />
    </div>
  );
}

export default App;
